import { hook } from 'Components/Hooks';

import ConfCommand from './ConfCommand';
import s from './strings';

export default function ConfPendingPanel({ ctx: { hooks, ctrl } }) {
  return (
    <div
      class="lcm-action-panel alert alert-success alert-icon rw-only"
      use:hook={hooks.show('confPending')}
    >
      {s.ConfPendingPanel.msg}

      <button type="button" class="btn btn-primary" onclick={() => ConfCommand.start()}>
        {s.ConfPendingPanel.buttonLabel}
      </button>
    </div>
  );
}
