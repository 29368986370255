import Alert from 'Components/Alert';
import { show, hide } from 'Components/domHelpers';
import DropzoneFactory from 'Components/DropzoneFactory';
import FileInput from 'Components/FileInput';
import { Cond, RefFormData, FormGroup, FormText } from 'Components/FormComponents';
import LoadingIndicator from 'Components/LoadingIndicator';
import MediaElementController from 'Components/MediaElementController';

import errors from './errors';
import s from './strings';

const DEFAULT_MAX_FILE_SIZE = 100 * 1024 * 1024 * 1024; // 100GB

export default class AudioUploadControl {
  static isClassComponent = true;

  constructor({ ref, onSubmit, showAbortButton = true, showDescriptionInput = true, maxFileSize = DEFAULT_MAX_FILE_SIZE, onAbort = null, children = null }) {
    if (ref) {
      ref(this);
    }

    this._form = new RefFormData();

    this._onSubmit = onSubmit;
    this._onAbort = onAbort;

    this._maxFileSize = maxFileSize;

    this.refs = {};

    this.root = (
      <div>
        <Alert ref={this.refs.alert} />
        <div class="form-horizontal" ref={this.refs.upload}>
          <FormGroup label={s.lblAudioFile}>
            <FileInput ref={this._fileInput} />
          </FormGroup>

          <Cond test={showDescriptionInput}>
            <FormText form={this._form} name="description" label={s.lblDescription} />
          </Cond>
          {children}
        </div>
        <div class="d-flex" ref={this.refs.pending}>
          <span class="control-label">{s.lblUploading}</span>
          <div class="flex-grow-1 ml-3">
            <progress class="progress-primary d-block w-100 mb-3" min="0" max="1" ref={this.refs.progress}></progress>
            <Cond test={showAbortButton}>
              <button type="button" class="btn btn-primary" onclick={() => this._abort()}>{s.lblCancel}</button>
            </Cond>
          </div>
        </div>
      </div>
    );

    this._converting = new LoadingIndicator(this.refs.pending, {
      label: s.lblConverting,
    });

    this._fileInput.on('change', () => {
      hide(this.refs.alert);

      if (showDescriptionInput) {
        this._form.set('description', this._fileInput.filename);
      }

      const { files: [ file ] } = this._fileInput;
      if (file) {
        if (file.size > maxFileSize) {
          this.showError('ERR_UPLOAD_FILE_TOO_LARGE');
        }
      }
    });
  }

  addDropzone(dropzoneTarget) {
    this._dropzone = DropzoneFactory.createDropzone(dropzoneTarget, s.lblDropFilesHere);
    this._dropzone.on('drop', e => {
      const { files } = e.dataTransfer;
      if (files[0]) {
        this._fileInput.setFile(files[0]);
      }
    });
  }

  render() {
    MediaElementController
      .stop()
      .catch(() => {
        // swallow errors
      });

    hide(this.refs.alert);
    hide(this.refs.pending);
    show(this.refs.upload);

    DropzoneFactory.useDropzone(this._dropzone);
  }

  clear() {
    this._fileInput.clear();
    if (this._form.has('description'))
      this._form.set('description', '');
  }

  submit() {
    const { files: [ file ] } = this._fileInput;

    if (!file) {
      return;
    }

    hide(this.refs.alert);
    hide(this.refs.upload);
    this._converting.hide();

    if (file.size > this._maxFileSize) {
      this.showError('ERR_UPLOAD_FILE_TOO_LARGE');
      return;
    }

    show(this.refs.pending);

    this._onSubmit({
      file,
      fileInput: this._fileInput,
      ...this._form.getAllValues(),
    });
  }

  _abort() {
    this.showError('ERR_UPLOAD_CANCELLED');

    if (this._onAbort)
      this._onAbort();
  }

  showError(code) {
    this.render();

    const { alert } = this.refs;
    alert.textContent = errors[code];
    show(alert);
  }

  renderProgress(progressEvent) {
    const { loaded, total } = progressEvent;
    this.progress = loaded / total;
    if (loaded === total) {
      this._converting.show();
    }
  }

  get progress() {
    return this.refs.progress.value;
  }

  set progress(value) {
    this.refs.progress.value = value;
  }
}
