import $ from 'jquery';

import './bootstrap3-typeahead';

import Alert from 'Components/Alert';
import ContextMenu from 'Components/ContextMenu';
import { cachedUpdate, show, hide } from 'Components/domHelpers';
import { Cond, RefFormData, FormText, FormCheckbox, FormPassthru } from 'Components/FormComponents';
import { hook, Hooks } from 'Components/Hooks';
import TbIcons from 'Components/TbIcons';
import { secondsToHHMMSS } from 'DateTime';

import CallListTable from './CallListTable';
import {
  ToggleButton,
  ModalGain,
  ModalConnectToCall,
} from './LcmComponents';
import CallCommand from './CallCommand';
import CommentDialog from './CommentDialog';
import { CallerInfoAddressBookModal } from './CallerInfoModal';
import getRecordingURLs from './getRecordingURLs';
import HandRaisingModal from './HandRaisingModal';
import isPSTN from './isPSTN';
import ModalConfirm from './ModalConfirm';
import playNameRecording from './playNameRecording';
import { ModalForm, SingleInputModal } from './ModalForm';
import WebCallLoader from './WebCallLoader';
import s from './strings';
import errors from './errors';

const SELECT_PROP = 'actionSelect';

const formatDuration = val => val === null ? '' : secondsToHHMMSS(val);
const formatRecordCalls = val => val === null
  ? ''
  : val === 1 ? s.lblOn : s.lblOff;
const formatConfMode = val => val === null ? '' : s.CONF_MODE_MAP[val];

export default class CallList {
  static isClassComponent = true;

  constructor({ config, ctrl, makeCallModal, ref }) {
    ref(this);

    this._ctrl = ctrl;
    this._handRaisingModal = new HandRaisingModal({ ctrl });

    this._pendingActionTarget       = null;

    this._modalEndConference = new ModalConfirm({
      title: s.lblDialogTitleEndConference,
      message: s.lblAreYouSureEndConference,
      confirmLabel: s.lblDialogTitleEndConference,
      danger: true,
      icon: TbIcons.CROSS_CIRCLE,
      confirm: () => {
        this._ctrl.endConference();
      },
    });

    this._modalMergeConference = new ModalConfirm({
      title: s.lblDialogTitleMergeSubConference,
      message: s.lblAreYouSureMergeConference,
      confirmLabel: s.lblDialogTitleMergeSubConference,
      icon: TbIcons.CONF_MERGE,
      confirm: () => {
        this._ctrl.endConference();
      },
    });

    this._modalAssignReference = new CommentDialog('active');

    this._modalSendToConf = new SingleInputModal({
      title: s.lblSendToAnotherConference,
      label: s.lblConferenceID,
      invalidErrorMsg: errors.ERR_INVALID_CONFERENCE_ID_REQUIRED,
      onSave: data => {
        const conferenceID = data.replace(/\D/g, '');
        CallCommand.sendToConference(this._pendingActionTarget, conferenceID);
      },
    });

    this._makeCallModal = makeCallModal;

    this._buttons = {};

    const hooks = this.hooks = new Hooks();

    const ctx = {
      hooks,
      ctrl,
    };

    this.root = (
      <>
        <div class="conf-controls">
          <div class="btn-group context-menu rw-only btn-select-all">
            <ToggleButton className="btn btn-primary" ref={this._buttons.selectionCheckbox} onclick={e => this._onSelectionCheckbox(e)} />
            <button type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ref={this._buttons.selectionMenu}><span></span></button>
            <ul class="dropdown-menu">
            </ul>
          </div>

          <button type="button" class="btn btn-primary rw-only btn-mute-all" ref={this._buttons.muteAll} onclick={() => this._ctrl.setSelectedMute(SELECT_PROP, true)}>{s.lblMuteAll}</button>
          <button type="button" class="btn btn-primary rw-only btn-unmute-all" ref={this._buttons.unmuteAll} onclick={() => this._ctrl.setSelectedMute(SELECT_PROP, false)}>{s.lblUnmuteAll}</button>

          <div class="context-menu rw-only" ref={this._selectionActionMenu}>
            <button type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ref={this._buttons.selectionActionButton}>
              <span>{s.lblAction}</span>
            </button>
            <ul class="dropdown-menu">
            </ul>
          </div>

          <ConfStatusReadWrite ctx={ctx} />

          <button
            type="button"
            class="btn btn-primary tbicon rw-only in-main-conf"
            title={s.lblEndConference}
            ref={this._buttons.endConference}
            onclick={() => this._modalEndConference.display()}
          >
            {TbIcons.CROSS_CIRCLE}
          </button>
          <button
            type="button"
            class="btn btn-primary tbicon rw-only in-sub-conf"
            title={s.lblMergeWithMainConference}
            ref={this._buttons.mergeConference}
            onclick={() => this._modalMergeConference.display()}
          >
            {TbIcons.CONF_MERGE}
          </button>
          <button
            type="button"
            class="btn btn-primary tbicon rw-only in-main-conf"
            title={s.Broadcast.enqueue}
            use:hook={hooks.show('broadcastAllowed')}
            ref={this._buttons.broadcastAddModalOpen}
            onclick={() => ctrl.broadcastController.addModalOpen()}
          >
            {TbIcons.PLAYLIST_PLAY}
          </button>
          <button
            type="button"
            class="btn btn-primary tbicon rw-only in-main-conf"
            title={s.lblEditReference}
            ref={this._buttons.assignReference}
            onclick={() => this._onAssignReference()}
          >
            {TbIcons.COMMENT}
          </button>
          <button
            type="button"
            class="btn btn-primary tbicon rw-only requires-make-call"
            title={s.lblDialOutToParticipant}
            ref={this._buttons.makeCall}
            onclick={() => this._makeCallModal.show()}
          >
            {TbIcons.MAKE_CALL}
          </button>

          <ConfStatusReadOnly ctx={ctx} />

          <input type="text" class="form-control conf-controls-filter" name="filter" aria-label={s.lblFilter} onkeyup={() => this.changeFilter()} ref={this._filterInput} />
        </div>

        <div class="table-position-wrapper">
          <div class="divCalls">
            <div
              class="status-overlays"
              use:hook={hooks.hide('state', state => state === 'active')}
            >
              <div
                class="panel panel-primary panel-icon"
                use:hook={hooks.show('state', state => state === 'pending')}
              >
                <div class="panel-heading">
                  <h3 class="panel-title in-main-conf">{s.lblConferencePending}</h3>
                  <h3 class="panel-title in-sub-conf">{s.lblSubConferencePending}</h3>
                </div>
                <div class="panel-body">
                  <div class="panel-icon-body">
                    <div class="icon"></div>
                    <div class="content">
                      <div class="in-main-conf">
                        <span class="message-text">{s.lblNoConferenceInProgress}</span><br/>
                        <button type="button" class="btn btn-link rw-only requires-make-call" onclick={() => this._makeCallModal.show()}>{s.lblDialOutToParticipant}</button><br/>
                        <Cond test={WebCallLoader.startPlayer}>
                          <button
                            type="button"
                            class="btn btn-link rw-only"
                            use:hook={hooks.show('webCallAllowed')}
                            onclick={() => WebCallLoader.startPlayer()}
                          >
                            {s.lblPlayAudioFile}
                          </button>
                        </Cond>
                        <div
                          class="rw-only"
                          use:hook={hooks.show('broadcastAllowed')}
                        >
                          <br/>
                          <button
                            type="button"
                            class="btn btn-link"
                            onclick={() => ctrl.broadcastController.addModalOpen()}
                          >
                            {s.Broadcast.enqueue}
                          </button>
                        </div>
                      </div>
                      <div class="in-sub-conf">
                        <span class="message-text">{s.lblNoSubConferenceInProgress}</span><br/>
                      </div>
                    </div>
                  </div>
                  <Cond test={config.confPendingMessage}>
                    <div class="mt-4" innerHTML={config.confPendingMessage} />
                  </Cond>
                </div>
              </div>

              <div
                class="panel panel-danger panel-icon"
                use:hook={hooks.show('state', state => state === 'inactive')}
              >
                <div class="panel-heading">
                  <h3 class="panel-title">{s.lblBridgeUnavailable}</h3>
                </div>
                <div class="panel-body">
                  <div class="panel-icon-body">
                    <div class="icon"></div>
                    <div class="content">
                      <span class="message-text">{s.lblBridgeTemporarilyUnavailable}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="panel panel-danger panel-icon connection-error"
                use:hook={hooks.show('state', state => state === 'error')}
              >
                <div class="panel-heading">
                  <h3 class="panel-title">{s.lblError}</h3>
                </div>
                <div class="panel-body">
                  <div class="panel-icon-body">
                    <div class="icon"></div>
                    <div class="content">
                      <span class="message-text">{s.lblRetryErrorMessage}</span><br/>
                      <span class="message-text">
                        {s.lblTryingAgain}
                        {' '}
                        <PollRetryMessage ctrl={ctrl} />
                      </span>
                      <button type="button" class="btn btn-link btn-retry" onclick={() => ctrl.retry()}>{s.lblRetryNow}</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <CallListTable ref={this._callListTable} ctrl={ctrl} colConfig={config.colConfig} fillerRowTotal={config.fillerRowTotal} onUpdate={() => this.render()} onCellButtonClick={e => this._onCellButtonClick(e)} />
          </div>
        </div>

        <ModalSendToSubConf ctx={ctx} ref={this._modalSendToSubConf} />
        <ModalGain ctx={ctx} ref={this._modalGain} />
        <ModalConnectToCall ctx={ctx} ref={this._modalConnectToCall} />
      </>
    );

    this._updaters = {
      isConfActive: cachedUpdate(val => {
        if (!val && ContextMenu.isOpen())
          ContextMenu.close();

        Object.values(this._buttons).forEach(button => button.disabled = !val);

        this._filterInput.disabled = !val;
        if (!val) {
          this._filterInput.value = '';
          this._callListTable.setFilter('');
        }

        this._callListTable.setDisabled(!val);
      }),
    };

    this.init(config);
  }

  render() {
    const {
      writable,
      isConfActive,

      setCallProps,
      totals,

      namePlayingParticipantID,
    } = this._ctrl;

    this._updaters.isConfActive(isConfActive);

    this.hooks.run(this._ctrl);

    if (ContextMenu.isOpen()) {
      return;
    }

    const { showCallerID } = this._ctrl.features;

    const extra = {
      writable,
      setCallProps,
      totals,
      namePlayingParticipantID,
      showCallerID,
    };

    this._callListTable.render(extra);

    const { allSelected, someSelected } = this._ctrl.getSelectState(SELECT_PROP);
    let checked = false;
    let indeterminate = false;
    if (allSelected) {
      checked = true;
    } else if (someSelected) {
      indeterminate = true;
    }
    this._buttons.selectionCheckbox.toggleAttribute('data-checked', checked);
    this._buttons.selectionCheckbox.toggleAttribute('data-indeterminate', indeterminate);

    if (someSelected) {
      this._buttons.muteAll.textContent = s.lblMute;
      this._buttons.unmuteAll.textContent = s.lblUnMute;
    } else {
      this._buttons.muteAll.textContent = s.lblMuteAll;
      this._buttons.unmuteAll.textContent = s.lblUnmuteAll;
    }
  }

  init(config) {
    this._callerInfoModal = new CallerInfoAddressBookModal({
      ctrl: this._ctrl,
    });

    this._modalDisconnectCall = new ModalConfirm({
      title: s.lblDialogTitleEndCall,
      message: s.lblAreYouSureDropCaller,
      confirm: () => {
        CallCommand.sendDisconnectCall(this._pendingActionTarget);
      },
    });

    // re-render when a contextMenu is closed
    $(this.root).on('hidden.tb.contextMenu', () => this.render());

    $(this.root).on('show.tb.contextMenu', '.btn-select-all', () => this._getSelectMenu());

    $(this._selectionActionMenu).on('show.tb.contextMenu', event => {
      return this._getActionMenu();
    });

    $(this._callListTable.root).on('show.tb.contextMenu', event => {
      const participantID = this._callListTable.getKeyByElement(event.target);
      const call = this._ctrl.getCallByParticipantID(participantID);

      return this._getActionMenu(call);
    });
  }

  filterSelect(type) {
    this._ctrl.select(SELECT_PROP, type);
  }

  openCallerInfo(participantID) {
    if (!this._ctrl.writable)
      return;

    const call = this._ctrl.getCallByParticipantID(participantID);

    this._callerInfoModal.display(call);
  }

  changeFilter() {
    this._callListTable.changeFilter(this._filterInput.value);
  }

  _onAssignReference() {
    this._modalAssignReference.open(this._ctrl.comment);
  }

  _onSelectionCheckbox(e) {
    const checked = e.target.hasAttribute('data-checked');
    this.filterSelect(checked ? 'none' : 'all');
  }

  _getActionMenu(call = null) {
    const menu = [];

    let participantID = null;
    let target = [];

    if (call) {
      participantID = call.participantID;
      target.push(call.callID);
    } else {
      target = this._ctrl
        .getCalls(call => !call.disconnected && call[SELECT_PROP])
        .map(call => call.callID);
    }

    this._pendingActionTarget = target;

    if (call && call.disconnected) {
      menu.push({
        label: (isPSTN(call)) ? s.lblAddToCallerList : s.lblUpdateCallerInfo,
        onclick: () => this.openCallerInfo(participantID),
      });

      if (isPSTN(call) && this._ctrl.makeCallAllowed) {
        menu.push({
          label: s.CallListMenu.dialOut,
          onclick: () => this._makeCallModal.show({
            phoneNumber: call.numberFormatted,
            name: call.name,
          }),
        });
      }

      return menu;
    }

    const someSelected = call
      ? true
      : this._ctrl.getSelectState(SELECT_PROP).someSelected;

    const wrapFn = fn => {
      if (call) {
        return fn;
      }

      return () => {
        fn();
        this.filterSelect('none');
      };
    };

    if (someSelected) {
      if (!call || !call.starred)
        menu.push({
          label: s.lblAddStar,
          onclick: wrapFn(() => CallCommand.setStar(target, 1)),
        });

      if (!call || call.starred)
        menu.push({
          label: s.lblRemoveStar,
          onclick: wrapFn(() => CallCommand.setStar(target, 0)),
        });


      if (this._ctrl.features.subConfs && !(call && call.isBroadcast)) {
        menu.push({
          label: s.lblSendToSubConference,
          onclick: wrapFn(() => this._modalSendToSubConf.show(target)),
        });

        if (this._ctrl.isSubConfActive) {
          menu.push({
            label: s.lblSendToMainConference,
            onclick: wrapFn(() => CallCommand.setSubConf(target, '')),
          });
        }
      }

      if (this._ctrl.operatorFlag) {
        menu.push({
          label: s.lblSendToAnotherConference,
          onclick: wrapFn(() => this._modalSendToConf.display()),
        });
      }

      if (!call || !call.host) {
        menu.push({
          label: s.lblPromote,
          onclick: wrapFn(() => CallCommand.setHost(target, 1)),
        });
      }

      if (call && call.muted)
        menu.push({
          label: s.lblUnMuteCaller,
          onclick: wrapFn(() => CallCommand.setMute(target, 0)),
        });

      if (call && !call.muted)
        menu.push({
          label: s.lblMuteCaller,
          onclick: wrapFn(() => CallCommand.setMute(target, 1)),
        });

      if (!call || call.hold)
        menu.push({
          label: s.lblTakeOffHold,
          onclick: wrapFn(() => CallCommand.setHold(target, 0)),
        });

      if (!call || !call.hold)
        menu.push({
          label: s.lblPlaceOnHold,
          onclick: wrapFn(() => CallCommand.setHold(target, 1)),
        });

      if (call) {
        menu.push({
          label: s.CallListMenu.setGain,
          onclick: wrapFn(() => this._modalGain.show(participantID)),
        });
      }

      if (this._ctrl.isClientCallConnected && call && !call.isClientCall) {
        menu.push({
          label: s.CallListMenu.connectToCall,
          onclick: wrapFn(() => this._modalConnectToCall.show(participantID)),
        });
      }

      if (this._ctrl.operatorFlag) {
        if (this._ctrl.isClientCallConnected && (!call || !call.isClientCall)) {
          menu.push({
            label: s.lblTalkToOperator,
            onclick: wrapFn(() => this._ctrl.talkToOperator(target)),
          });
        }

        if (call && !call.isClientCall) {
          if (!call.operatorHelpRequested) {
            menu.push({
              label: s.lblSendToHelpQueue,
              onclick: wrapFn(() => this._ctrl.sendToHelpQueue(participantID)),
            });
          } else {
            menu.push({
              label: s.lblRemoveFromHelpQueue,
              onclick: wrapFn(() => this._ctrl.removeFromHelpQueue(participantID)),
            });
          }

          menu.push({
            label: s.lblSendToEnterQueue,
            onclick: wrapFn(() => this._ctrl.sendToEnterQueue(participantID)),
          });
        }
      }

      menu.push({
        label: (call ? s.lblDropCaller : s.lblDropCallers),
        onclick: wrapFn(() => this._modalDisconnectCall.display()),
      });

      if (call)
        menu.push({
          label: (isPSTN(call)) ? s.lblAddToCallerList : s.lblUpdateCallerInfo,
          onclick: wrapFn(() => this.openCallerInfo(participantID)),
        });

      if (call && call.handRaisedIndexDisplay === null) {
        menu.push({
          label: s.lblRaiseHand,
          onclick: wrapFn(() => {
            if (participantID) {
              this._ctrl.handRaise(participantID);
            }
          }),
        });
      }

      if (!call || call.handRaisedIndexDisplay !== null) {
        menu.push({
          label: s.lblLowerHand,
          onclick: wrapFn(() => {
            if (participantID) {
              this._ctrl.handLower(participantID);
            } else {
              this._ctrl.handLowerSelected(SELECT_PROP);
            }
          }),
        });
      }

      if (call && isPSTN(call) && this._ctrl.makeCallAllowed) {
        menu.push({
          label: s.CallListMenu.dialOut,
          onclick: wrapFn(() => this._makeCallModal.show({
            phoneNumber: call.numberFormatted,
            name: call.name,
          })),
        });
      }
    } else {
      menu.push({
        label: s.lblRemoveAllStars,
        onclick: wrapFn(() => this._ctrl.unstarAll()),
      });
    }

    if (!call) {
      if (this._ctrl.handRaisingPrevSelectedCallID) {
        menu.push({
          label: s.lblReselectLastSelectedCaller,
          onclick: wrapFn(() => this._ctrl.handReselectLast()),
        });
      }

      if (WebCallLoader.startPlayer && this._ctrl.webCallAllowed) {
        menu.push({
          label: s.lblPlayAudioFile,
          onclick: wrapFn(() => WebCallLoader.startPlayer()),
        });
      }

      if (!someSelected) {
        menu.push({
          disabled: true,
          label: s.lblSelectCallsToSeeMoreActions,
        });
      }
    }

    return menu;
  }

  _getSelectMenu() {
    return [
      {
        label: s.lblAll,
        onclick: () => this.filterSelect('all'),
      },
      {
        label: s.lblNone,
        onclick: () => this.filterSelect('none'),
      },
      {
        label: s.lblStarred,
        onclick: () => this.filterSelect('starred'),
      },
      {
        label: s.lblHosts,
        onclick: () => this.filterSelect('hosts'),
      },
      {
        label: s.lblParticipants,
        onclick: () => this.filterSelect('participants'),
      },
      {
        label: s.lblSelectRaisedHands,
        onclick: () => this.filterSelect('raisedHands'),
      },
      {
        label: s.lblOnHold,
        onclick: () => this.filterSelect('onHold'),
      }
    ];
  }

  _onCellButtonClick({ key: participantID, colId }) {
    switch (colId) {
    case 'nameRecorded':
      this._playNameRecording(participantID);
      break;

    case 'callerName':
      this.openCallerInfo(participantID);
      break;

    case 'handRaised':
      this._handRaisingModal.open(participantID);
      break;

    case SELECT_PROP:
    case 'starred':
    case 'host':
    case 'muted':
      this._ctrl.toggleCallProperty(participantID, colId);
      break;
    }
  }

  _playNameRecording(participantID) {
    const { callID } = this._ctrl.getCallByParticipantID(participantID);
    const urls = getRecordingURLs('', 'LCM', 'getCallNameRecording', { callID });

    playNameRecording(urls, this._ctrl.namePlayingParticipantID === participantID, isPlaying => {
      this._ctrl.namePlayingParticipantID = isPlaying ? participantID : null;
    });
  }
}

function ConfStatusReadWrite({ ctx: { hooks } }) {
  return (
    <div class="conf-status rw-only">
      <div>
        <span class="status-label">{s.lblStatusCallers}:</span>
        <span use:hook={hooks.text('callCountDisplay')} />
        <span use:hook={hooks.show('callCountTotalDisconnected')}>
          <span class="status-label tbicon">{TbIcons.CALL_DISCONNECTED}</span>
          <span use:hook={hooks.text('callCountTotalDisconnected')} />
        </span>
      </div>

      <div>
        <span class="status-label">{s.lblStatusStarted}:</span>
        <span use:hook={hooks.text('startedDateDisplay')} />
      </div>

      <div>
        <span class="status-label">{s.lblStatusDuration}:</span>
        <span use:hook={hooks.text('duration', formatDuration)} />
      </div>
    </div>
  );
}

function ConfStatusReadOnly({ ctx: { hooks } }) {
  return (
    <div class="conf-status ro-only">
      <div class="in-main-conf">
        <span class="status-label">{s.lblMode}:</span>
        <span use:hook={hooks.text('confMode', formatConfMode)} />
        <span class="tbicon" use:hook={hooks.show('locked')}>
          {TbIcons.LOCK}
        </span>
      </div>

      <div>
        <span class="status-label">{s.lblStatusCallers}:</span>
        <span use:hook={hooks.text('callCountDisplay')} />
      </div>

      <div>
        <span class="status-label">{s.lblStatusStarted}:</span>
        <span use:hook={hooks.text('startedDateDisplay')} />
      </div>

      <div>
        <span class="status-label">{s.lblStatusDuration}:</span>
        <span use:hook={hooks.text('duration', formatDuration)} />
      </div>

      <div class="in-main-conf">
        <span class="status-label">{s.lblRecording}:</span>
        <span use:hook={hooks.text('recordCalls', formatRecordCalls)} />
      </div>
    </div>
  );
}

function PollRetryMessage({ ctrl }) {
  const hooks = new Hooks();

  ctrl.on('retryTimer', () => hooks.run(ctrl));

  return (
    <span
      use:hook={hooks.text('retryWaitSecs', secs => secs === 0 ? s.lblTryingAgainNow : `${s.lblTryingAgainIn} ${secs}s`)}
    />
  );
}

class ModalSendToSubConf {
  static isClassComponent = true;

  constructor({ ctx: { ctrl }, ref }) {
    if (ref) {
      ref(this);
    }

    this._form = new RefFormData();

    const onSubmit = () => {
      const {
        targets,
        subConfID,
        muted,
        hold,
      } = this._form.getAllValues();

      if (!subConfID) {
        show(this._alert);
        return;
      }

      this._modalForm.hide();

      ctrl.sendToSubConf(targets, subConfID, { muted, hold });
    };

    this.root = (
      <ModalForm
        title={s.lblSendToSubConference}
        onSubmit={onSubmit}
        ref={this._modalForm}
      >
        <Alert ref={this._alert} msg={errors.ERR_INVALID_SUB_CONFERENCE_REQUIRED} />
        <div class="form-horizontal">
          <FormPassthru form={this._form} name="targets" />
          <FormText
            form={this._form}
            name="subConfID"
            label={s.lblSubConference}
            inputAttributes={{ autocomplete: 'off' }}
          />
          <FormCheckbox
            form={this._form}
            name="muted"
            label={s.lblMuteCaller}
          />
          <FormCheckbox
            form={this._form}
            name="hold"
            label={s.lblPlaceOnHold}
          />
        </div>
      </ModalForm>
    );

    $(this._form.getInput('subConfID')).typeahead({
      minLength: 0,
      items: 'all',
      showHintOnFocus: true,
      fitToElement: true,
      matcher: () => true,
      source: (search, callback) => {
        const munged = search.toLowerCase();
        callback(ctrl.subConfs.filter(subConf => subConf.name.toLowerCase().includes(munged)));
      },
      displayText: result => result.name,
    });
  }

  show(targets) {
    hide(this._alert);

    this._form.setValues({
      targets,
      subConfID: '',
      muted: false,
      hold: false,
    });

    this._modalForm.show();
  }
}
