import { toggle, toggleClass, cachedUpdate } from 'Components/domHelpers';
import { hook, Hooks } from 'Components/Hooks';
import TbIcons from 'Components/TbIcons';

import CallCommand from './CallCommand';
import HandRaisingTable from './HandRaisingTable';
import { ToggleButton } from './LcmComponents';
import ModalConfirm from './ModalConfirm';
import s from './strings';

const SELECT_PROP = 'handRaisingChecked';

export default class HandRaising {
  static isClassComponent = true;

  constructor({ ctrl, ref }) {
    ref(this);

    this._ctrl = ctrl;

    this._labels = {};
    this._buttons = {};

    const hooks = this.hooks = new Hooks();

    this.root = (
      <div class="hand-raising-control">
        <table class="nowrap dataTable hand-raising-table">
          <tbody>
            <tr>
              <td class="drag-handle"></td>
              <td class="hover-cell">
                <strong class="control-label text-primary">{s.handRaising.selected}</strong>
              </td>
              <td class="handRaisedIndexDisplay"></td>

              <td ref={this._selectionLabel}></td>

              <td class="actions">
                <div class="btn-toolbar-discrete" role="group" ref={this._selectionActions}>
                  <button type="button" class="btn btn-primary" onclick={() => this.selectedToggleMute()} ref={this._selectedToggleMuteButton}>{s.lblMute}</button>
                  <button type="button" class="btn btn-primary" onclick={() => this.selectedRelease()}>{s.handRaising.release}</button>
                  <button type="button" class="btn btn-primary" onclick={() => this.selectedReturnToQueue()}>{s.handRaising.returnToQueue}</button>
                </div>
              </td>
              <td>
                {/* empty td to match empty td in HandRaisingTable */}
              </td>
            </tr>
          </tbody>
        </table>

        <div ref={this._queueControls}>
          <div class="btn-toolbar-discrete hand-raising-table-controls" role="group">
            <ToggleButton className="btn btn-primary" ref={this._buttons.selectionCheckbox} onclick={e => this._onSelectionCheckbox(e)} />
            <button type="button" class="btn btn-primary" ref={this._buttons.lower} onclick={() => this.lower()}>{s.handRaising.lower}</button>
            <button type="button" class="btn btn-primary" use:hook={hooks.show('handRaisingLowerAllAllowed')} onclick={() => this.lowerAll()}>{s.handRaising.lowerAll}</button>
            <button type="button" class="btn btn-primary" use:hook={hooks.prop('handRaisingPrevSelectedCallID', 'disabled', val => !val)} onclick={() => ctrl.handReselectLast()}>{s.handRaising.reselectLast}</button>
            <button type="button" class="btn btn-primary" ref={this._buttons.moveTop} onclick={() => this.move('top')}>{s.handRaising.moveToTop}</button>
            <button type="button" class="btn btn-primary" ref={this._buttons.moveUp} onclick={() => this.move('up')}>{s.handRaising.moveUp}</button>
            <button type="button" class="btn btn-primary" ref={this._buttons.moveDown} onclick={() => this.move('down')}>{s.handRaising.moveDown}</button>
            <button type="button" class="btn btn-primary" ref={this._buttons.moveBottom} onclick={() => this.move('bottom')}>{s.handRaising.moveToBottom}</button>
            <button type="button" class="btn btn-primary" ref={this._buttons.toggleExpand} onclick={() => this._toggleExpand()}>
              <span ref={this._labels.expandText}></span> (<span ref={this._labels.count}></span>)
            </button>
          </div>

          <HandRaisingTable ref={this._handRaisingTable} onCellButtonClick={e => this._onCellButtonClick(e)} onMove={e => this._onMove(e)} />

          <div class="expand-indicator">
            <div class="expand-indicator-inner" onclick={() => this._toggleExpand()}>
              <span ref={this._labels.expandText2}></span> (<span ref={this._labels.count2}></span>)<span ref={this._labels.expandIcon} class="tbicon"></span>
            </div>
          </div>
        </div>
      </div>
    );

    this._updaters = {
      expanded: cachedUpdate(val => {
        const expandLabel = val
          ? s.handRaising.collapse
          : s.handRaising.expand;

        this._labels.expandText.textContent = expandLabel;
        this._labels.expandText2.textContent = expandLabel;

        this._labels.expandIcon.textContent = val
          ? TbIcons.MENU_UP
          : TbIcons.MENU_DOWN;

        toggleClass(this.root, 'expanded', val);
        toggleClass(this._handRaisingTable.root, 'draggable', val);
      }),
      count: cachedUpdate(val => {
        this._labels.count.textContent = val;
        this._labels.count2.textContent = val;

        const expandable = val > 1;
        toggleClass(this.root, 'expandable', expandable);
        this._buttons.toggleExpand.disabled = !expandable;
      }),
      isSelectAllowed: cachedUpdate(val => {
        toggleClass(this.root, 'select-allowed', val);
      }),
    };

    this._expanded = false;

    this._lowerAllConfirmModal = new ModalConfirm({
      title: s.handRaising.lowerAll,
      message: s.handRaising.confirmLowerAll,
      confirmLabel: s.handRaising.lowerAll,
      confirm: () => this._ctrl.handLowerAll(),
    });
  }

  _toggleExpand() {
    this._expanded = !this._expanded;
    if (this._expanded)  {
      this.render();
    } else {
      // select() internally triggers re-render
      this.filterSelect('none');
    }
  }

  lower() {
    this._ctrl.handLowerSelected(SELECT_PROP);
  }

  lowerAll() {
    this._lowerAllConfirmModal.display();
  }

  move(direction) {
    const selected = this._ctrl.getSelectedCalls(SELECT_PROP);
    if (selected.length === 1) {
      const { participantID } = selected[0];
      this._ctrl.handMove(participantID, direction);
    } else if (selected.length > 1) {
      this._ctrl.handMoveMultiple(selected, direction);
    }
  }

  selectedReturnToQueue() {
    CallCommand.raisedHandSelectCancel(this.selectedCall.call.callID, this.selectedCall.origMuted);
  }

  selectedToggleMute() {
    const muted = this.selectedCall.call.muted ? 0 : 1;

    CallCommand.setMute(this.selectedCall.call.callID, muted);
  }

  selectedRelease() {
    CallCommand.raisedHandSelectRelease(this.selectedCall.call.callID, this.selectedCall.origMuted);
  }

  render() {
    const { canManageHands } = this._ctrl;
    const {
      selected,
      isSelectAllowed,
      showSelectionActions,
    } = this._ctrl.hands;

    if (this._handRaisingTable.isDragging) {
      return;
    }

    const calls = this._ctrl.getCalls(
      call => call.handRaisedIndexDisplay,
      (a, b) => {
        const aval = a.handRaisedIndexDisplay;
        const bval = b.handRaisedIndexDisplay;

        if (aval === bval)
          return 0;

        return (aval < bval) ? -1 : 1;
      }
    );

    const showQueueControls = canManageHands && calls.length;
    const showRoot = selected || showQueueControls;

    // collapse if this.root or queue controls are not shown
    if (!showRoot || !showQueueControls) this._expanded = false;
    if (!(calls.length > 1)) this._expanded = false;

    this._updaters.expanded(this._expanded);
    this._updaters.count(calls.length);
    this._updaters.isSelectAllowed(isSelectAllowed);

    this._handRaisingTable.render(calls);

    const { allSelected, someSelected, count } = this._ctrl.getSelectState(SELECT_PROP, call => call.handRaisedIndexDisplay);
    let checked = false;
    let indeterminate = false;
    if (allSelected) {
      checked = true;
    } else if (someSelected) {
      indeterminate = true;
    }
    this._buttons.selectionCheckbox.toggleAttribute('data-checked', checked);
    this._buttons.selectionCheckbox.toggleAttribute('data-indeterminate', indeterminate);

    const singleOnlyDisabled = count !== 1;
    this._buttons.lower.disabled = !count;
    this._buttons.moveTop.disabled = !count;
    this._buttons.moveBottom.disabled = !count;
    this._buttons.moveUp.disabled = singleOnlyDisabled;
    this._buttons.moveDown.disabled = singleOnlyDisabled;

    this._updateSelectedCall(selected);

    toggle(this._selectionActions, showSelectionActions);
    toggle(this._queueControls, showQueueControls);
    toggle(this.root, showRoot);

    this.hooks.run(this._ctrl);
  }

  _updateSelectedCall(selectedCall) {
    if (selectedCall) {
      this.selectedCall = selectedCall;

      this._selectedToggleMuteButton.textContent = selectedCall.call.muted
        ? s.lblUnMute
        : s.lblMute;

      this._selectionLabel.textContent = selectedCall.call.callerNameCallerID;
    } else {
      this.selectedCall = null;

      this._selectionLabel.textContent = s.lblNone;
    }
  }

  _onSelectionCheckbox(e) {
    const checked = e.target.hasAttribute('data-checked');
    this.filterSelect(checked ? 'none' : 'all');
  }

  _onCellButtonClick({ button, key: participantID, colId }) {
    switch (colId) {
    case SELECT_PROP:
      this._ctrl.toggleCallProperty(participantID, colId);
      break;

    case 'actions':
      switch (button.getAttribute('data-action')) {
      case 'handSelect':
        this._ctrl.handSelect(participantID);
        break;
      case 'handLower':
        this._ctrl.handLower(participantID);
        break;
      }
      break;
    }
  }

  _onMove({ participantID, direction, targetParticipantID = null }) {
    this._ctrl.handMove(participantID, direction, targetParticipantID);
  }

  filterSelect(type) {
    this._ctrl.select(SELECT_PROP, type, call => call.handRaisedIndexDisplay);
  }
}
