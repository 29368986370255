import { hide, show, toggle, toggleClass, emptyNode } from './domHelpers';

export class RefFormData {
  constructor() {
    this.fields = {};
  }

  addField(name, { root, el, label, getValue = false, setValue = false }) {
    this.fields[name] = {
      root,
      el,
      label,
      getValue,
      setValue,
    };
  }

  getValues(names) {
    if (!names) {
      throw new TypeError('invalid args');
    }

    return names.reduce((acc, name) => {
      acc[name] = this.get(name);
      return acc;
    }, {});
  }

  getAllValues() {
    return this.getValues(Object.keys(this.fields));
  }

  getFields(names) {
    if (!names) {
      throw new TypeError('invalid args');
    }

    return names.map(name => {
      const value = this.get(name);
      const { label } = this.fields[name];

      return {
        name,
        label,
        value,
      };
    });
  }

  getAllFields() {
    return this.getFields(Object.keys(this.fields));
  }

  get(name) {
    if (!this.has(name)) {
      throw new TypeError(`Unknown field ${name}`);
    }

    const field = this.fields[name];

    if (field.getValue) {
      return field.getValue();
    }

    throw new TypeError(`getValue not defined for ${name}`);
  }

  has(name) {
    return name in this.fields;
  }

  set(name, value) {
    if (!this.has(name)) {
      throw new TypeError(`Unknown field ${name}`);
    }

    const field = this.fields[name];

    if (field.setValue) {
      field.setValue(value);
      return;
    }

    throw new TypeError(`setValue not defined for ${name}`);
  }

  setValues(obj) {
    Object.entries(obj).forEach(([ name, value ]) => this.set(name, value));
  }

  getInput(name) {
    if (!this.has(name)) {
      throw new TypeError(`Unknown field ${name}`);
    }

    return this.fields[name].el;
  }

  get idPrefix() {
    return this._idPrefix || this._createIdPrefix();
  }

  _createIdPrefix() {
    this._idPrefix = generateUniqueId();
    return this._idPrefix;
  }

  getFieldId(name) {
    return `${this.idPrefix}${name.replaceAll('_', '__').replaceAll(' ', '_')}`;
  }

  setVisibility(name, flag) {
    if (!this.has(name)) {
      throw new TypeError(`Unknown field ${name}`);
    }

    const { root } = this.fields[name];

    toggle(root, flag);
  }

  setDisabled(name, flag) {
    if (!this.has(name)) {
      throw new TypeError(`Unknown field ${name}`);
    }

    const { el } = this.fields[name];

    if (el && 'disabled' in el) el.disabled = !!flag;
  }

  setReadOnly(name, flag) {
    if (!this.has(name)) {
      throw new TypeError(`Unknown field ${name}`);
    }

    const { el } = this.fields[name];

    if (el && 'readOnly' in el) el.readOnly = !!flag;
  }
}

let _generateUniqueIdCtr = 0;
export function generateUniqueId(prefix = 'zzz') {
  return `${prefix}${_generateUniqueIdCtr++}`;
}

export function Cond(props) {
  if (props.test) {
    return props.children;
  }
}

export function FormField(props) {
  let rootClass = 'form-group';
  if (props.rootClass)
    rootClass += ` ${props.rootClass}`;

  if (props.inline) {
    return (
      <div class={rootClass} ref={props.ref}>
        <Cond test={props.label}>
          <label class="control-label" for={props.id}>{props.label}</label>
        </Cond>
        {props.children}
      </div>
    );
  }

  const { comment = null, commentCol = false } = props;
  const labelCol = !commentCol ? 'col-sm-4' : 'col-sm-3';
  const inputCol = !commentCol ? 'col-sm-8' : 'col-sm-4';

  return (
    <div class={rootClass} ref={props.ref}>
      {props.label
        ? <label class={`control-label ${labelCol}`} for={props.id}>{props.label}</label>
        : <div class={labelCol}></div>}

      <div class={inputCol}>
        {props.children}
        <Cond test={!commentCol && comment}>
          <div class="form-comment">{comment}</div>
        </Cond>
      </div>

      <Cond test={commentCol && comment}>
        <div class="col-xs-5">{comment}</div>
      </Cond>
    </div>
  );
}

export function FormGroup(props) {
  let rootClass = 'form-group';
  if (props.rootClass)
    rootClass += ` ${props.rootClass}`;

  if (props.inline) {
    return (
      <div class={rootClass} ref={props.ref}>
        <Cond test={props.label}>
          <div class="control-label">{props.label}</div>
        </Cond>
        {props.children}
      </div>
    );
  }

  const { comment = null, commentCol = false } = props;
  const labelCol = !commentCol ? 'col-sm-4' : 'col-sm-3';
  const inputCol = !commentCol ? 'col-sm-8' : 'col-sm-4';

  return (
    <div class={rootClass} ref={props.ref}>
      {props.label
        ? <div class={`control-label ${labelCol}`}>{props.label}</div>
        : <div class={labelCol}></div>}

      <div class={inputCol}>
        {props.children}
        <Cond test={!commentCol && comment}>
          <div class="form-comment">{comment}</div>
        </Cond>
      </div>

      <Cond test={commentCol && comment}>
        <div class="col-xs-5">{comment}</div>
      </Cond>
    </div>
  );
}

export function FormText(props) {
  const { form, inline = false, name, rootClass, label, comment = null, commentCol = false, value = '', readonly = false, inputAttributes, type = 'text', trim = true } = props;
  const id = form.getFieldId(name);

  let root;
  const register = el => {
    form.addField(name, {
      root,
      el,
      label,
      getValue() {
        const { value } = el;
        return trim
          ? value.trim()
          : value;
      },
      setValue(val) {
        el.value = val;
      },
    });
  };

  return (
    <FormField inline={inline} id={id} rootClass={rootClass} label={label} comment={comment} commentCol={commentCol} ref={root}>
      <InputElement type={type} name={name} id={id} value={value} readonly={readonly} attributes={inputAttributes} ref={register} />
    </FormField>
  );
}

export function FormTextarea(props) {
  const { form, inline = false, name, rootClass, label, comment = null, commentCol = false, value = '', readonly = false, inputAttributes, trim = true } = props;
  const id = form.getFieldId(name);

  let root;
  const register = el => {
    form.addField(name, {
      root,
      el,
      label,
      getValue() {
        const { value } = el;
        return trim
          ? value.trim()
          : value;
      },
      setValue(val) {
        el.value = val;
      },
    });
  };

  return (
    <FormField inline={inline} id={id} rootClass={rootClass} label={label} comment={comment} commentCol={commentCol} ref={root}>
      <Textarea name={name} id={id} value={value} readonly={readonly} attributes={inputAttributes} ref={register} />
    </FormField>
  );
}

export function FormPassword(props) {
  const { form, inline = false, name, rootClass, label, comment = null, commentCol = false, value = '', readonly = false, inputAttributes } = props;
  const id = form.getFieldId(name);

  let root;
  const register = el => {
    form.addField(name, {
      root,
      el,
      label,
      getValue() {
        return el.value;
      },
      setValue(val) {
        el.value = val;
      },
    });
  };

  return (
    <FormField inline={inline} id={id} rootClass={rootClass} label={label} comment={comment} commentCol={commentCol} ref={root}>
      <InputElement type="password" name={name} id={id} value={value} readonly={readonly} attributes={inputAttributes} ref={register} />
    </FormField>
  );
}

export function FormSelect(props) {
  const { form, inline = false, name, rootClass, label, comment = null, commentCol = false, value = '', inputAttributes, options = [] } = props;
  const id = form.getFieldId(name);

  let root;
  const register = el => {
    form.addField(name, {
      root,
      el,
      label,
      getValue() {
        return el.value;
      },
      setValue(val) {
        el.value = val;
      },
    });
  };

  return (
    <FormField inline={inline} id={id} rootClass={rootClass} label={label} comment={comment} commentCol={commentCol} ref={root}>
      <SelectElement name={name} id={id} value={value} attributes={inputAttributes} options={options} ref={register} />
    </FormField>
  );
}

export function FormCheckbox(props) {
  const { form, inline = false, name, rootClass, label = '', labelRight = '', comment = null, commentCol = false } = props;
  const id = form.getFieldId(name);

  let root;
  const register = el => {
    form.addField(name, {
      root,
      el,
      label: label || labelRight,
      getValue() {
        return el.checked;
      },
      setValue(val) {
        el.checked = !!val;
      },
    });
  };

  return (
    <FormField inline={inline} id={id} rootClass={rootClass} label={label} comment={comment} commentCol={commentCol} ref={root}>
      <CheckboxElements name={name} id={id} label={labelRight} ref={register} />
    </FormField>
  );
}

export function FormStatic(props) {
  const { form, inline = false, name, rootClass, label, comment = null, commentCol = false, value = '' } = props;

  let root;
  const register = el => {
    form.addField(name, {
      root,
      el,
      label,
      getValue() {
        return el.textContent;
      },
      setValue(val) {
        el.textContent = val;
      },
    });
  };

  return (
    <FormGroup inline={inline} rootClass={rootClass} label={label} comment={comment} commentCol={commentCol} ref={root}>
      <p class="form-control-static" ref={register}>{value}</p>
    </FormGroup>
  );
}

export function FormHidden(props) {
  const { form, name, value = '', trim = true } = props;
  const id = form.getFieldId(name);

  const register = el => {
    form.addField(name, {
      root: null,
      el,
      label: null,
      getValue() {
        const { value } = el;
        return trim
          ? value.trim()
          : value;
      },
      setValue(val) {
        el.value = val;
      },
    });
  };

  return (
    <input type="hidden" name={name} id={id} value={value} ref={register}/>
  );
}

export function FormPassthru(props) {
  const { form, name, value = null } = props;

  let curValue = value;
  form.addField(name, {
    root: null,
    el: null,
    label: null,
    getValue() {
      return curValue;
    },
    setValue(val) {
      curValue = val;
    },
  });

  return '';
}

export function FormComputed(props) {
  const { form, name, label, getValue, setValue } = props;

  form.addField(name, {
    root: null,
    el: null,
    label,
    getValue,
    setValue,
  });
}

export function InputElement(props) {
  const { type = 'text', name, id, value = '', readonly = false, attributes = {} } = props;

  return (
    <input class="form-control" type={type} name={name} id={id} value={value} readonly={readonly} {...attributes} ref={props.ref}/>
  );
}

export function Textarea(props) {
  const { name, id, value = '', readonly = false, attributes = {} } = props;

  return (
    <textarea class="form-control" name={name} id={id} value={value} readonly={readonly} {...attributes} ref={props.ref}>{value}</textarea>
  );
}

export function SelectElement(props) {
  const { name, id, value = '', attributes = {}, options = [] } = props;

  return (
    <select class="form-control" name={name} id={id} {...attributes} ref={props.ref}>
      {options.map(opt => (
        <option value={opt.value} selected={opt.value === value}>{opt.label}</option>
      ))}
    </select>
  );
}

export function CheckboxElements(props) {
  return (
    <>
      <input type="checkbox" name={props.name} id={props.id} ref={props.ref}/>
      <label for={props.id}>
        <div>{props.label}</div>
      </label>
    </>
  );
}

export class FormError {
  static isClassComponent = true;

  constructor({ icon = true, hideType = 'hidden', ref }) {
    ref(this);

    this._hideType = hideType;

    this.root = (
      <div classList={{ alert: true, 'alert-danger': true, 'alert-icon': icon }}></div>
    );
  }

  render(error) {
    if (!error || !error.length) {
      this._hide();
      return;
    }

    emptyNode(this.root);

    let singleMessage = false;
    if (Array.isArray(error)) {
      if (error.length === 1) {
        singleMessage = error[0].message;
      }
    } else {
      singleMessage = error;
    }

    if (singleMessage) {
      this.root.textContent = singleMessage;
    } else {
      this.root.appendChild(
        <div>
          <p>Please correct the following items to proceed:</p>

          <ul>
            {error.map(err => <li>{err.message}</li>)}
          </ul>
        </div>
      );
    }

    this._show();
    window.scrollTo(0, 0);
  }

  _show() {
    switch (this._hideType) {
    case 'hidden':
      show(this.root);
      break;

    case 'invisible':
      toggleClass(this.root, 'invisible', false);
      break;
    }
  }

  _hide() {
    switch (this._hideType) {
    case 'hidden':
      hide(this.root);
      break;

    case 'invisible':
      toggleClass(this.root, 'invisible', true);
      break;
    }
  }
}

export function wrapSubmitHandler(fn) {
  return e => {
    e.preventDefault();
    fn(e);
  };
}

export function objectToOptionsArray(obj) {
  return Object.entries(obj).map(([ value, label ]) => ({
    value,
    label,
  }));
}
